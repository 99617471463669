import { loginRequest } from '@/app/services/cashAppApi';
import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  InputGroup,
  Stack,
  useColorModeValue,
} from '@chakra-ui/react';
import ErrorComponent from '../common/ErrorComponent';
import Loading from '../common/Loading';
import Content from '@/features/common/Content.tsx';
import React, { useEffect, useRef, useState } from 'react';
import FileUpload from '@/features/common/FileUpload.tsx';
import { RequestForm, useRequestMutation } from '@/features/requests/requestsApi.ts';
import { LogMessage, setLogMessage } from '@/features/notification/notificationSlice.ts';
import { useAppDispatch } from '@/app/hooks.ts';
import FormErrors from '@/features/requests/FormErrors.tsx';
import { AnimatePresence } from 'framer-motion';
import { FormErrorType } from '@/app/services/helpers.ts';

function formatDate(date: string) {
  const d = new Date(date);
  let month = '' + (d.getMonth() + 1),
    day = '' + d.getDate();
  const year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
}

const Form = () => {
  const dispatch = useAppDispatch();
  const isDateModified = useRef(false);
  const isFileModified = useRef(false);
  const [paymentDate, setPaymentDate] = useState<string>(formatDate(new Date().toDateString()));
  const [resetFile, setResetFile] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);
  const labelColor = useColorModeValue('gray.500', 'gray.400');

  const [submit, { isSuccess, isError, error }] = useRequestMutation();

  const handleApplyDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    isDateModified.current = true;
    const date = event.target.value;
    setPaymentDate(date);
  };

  const handleFileLoaded = (file: File | null) => {
    isFileModified.current = true;
    setResetFile(false);
    setFile(file);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (paymentDate && file) {
      const payload: RequestForm = {
        paymentDate,
        file,
      };
      submit(payload)
        .unwrap()
        .then(() => {
          setFile(null);
          setPaymentDate('');
          setResetFile(true);
          isDateModified.current = false;
          isFileModified.current = false;
        })
        .catch((error) => console.error(error));
    }
  };

  useEffect(() => {
    if (isSuccess) {
      const payload: LogMessage = {
        msg: 'File successfully uploaded',
        status: 'success',
      };
      dispatch(setLogMessage(payload));
    }
  }, [isSuccess]);

  return (
    <Box>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={loginRequest}
        errorComponent={ErrorComponent}
        loadingComponent={Loading}
      >
        <Content header="Request Form">
          <form aria-label="request-form" name="request-form" onSubmit={handleSubmit}>
            <Stack spacing={2} width={'50%'} pos={'relative'}>
              <FormControl isInvalid={!paymentDate && isDateModified.current} aria-label="request-form-control">
                <Grid templateColumns="120px auto" py={3}>
                  <GridItem pt={'5px'}>
                    <FormLabel
                      color={labelColor}
                      fontSize="md"
                      fontWeight={300}
                      htmlFor="apply-date"
                      mb={0}
                      textAlign={'end'}
                    >
                      Apply date:
                    </FormLabel>
                  </GridItem>
                  <GridItem flex={1}>
                    <InputGroup display="block" sx={{ zIndex: 100 }}>
                      <Input
                        id="apply-date"
                        aria-label="date"
                        placeholder="Select Date"
                        size="md"
                        type="date"
                        onChange={handleApplyDateChange}
                        value={paymentDate}
                      />
                      <FormErrorMessage mt={4} sx={{ flexBasis: '100%', height: 0 }}>
                        Date is required
                      </FormErrorMessage>
                    </InputGroup>
                  </GridItem>
                </Grid>
              </FormControl>
              <FormControl isInvalid={!file && isFileModified.current}>
                <Grid templateColumns="120px auto" py={3}>
                  <GridItem pt={'5px'}>
                    <FormLabel
                      color={labelColor}
                      fontSize="md"
                      fontWeight={300}
                      htmlFor="upload-file"
                      mb={0}
                      textAlign={'end'}
                    >
                      File:
                    </FormLabel>
                  </GridItem>
                  <GridItem flex={1}>
                    <FileUpload
                      id="upload-file"
                      isDisabled={false}
                      isReset={resetFile}
                      fileLoadedEvent={handleFileLoaded}
                      uploadSuccess={true}
                      uploadError={false}
                      errorMessage="File is required"
                    />
                  </GridItem>
                </Grid>
              </FormControl>
            </Stack>
            <AnimatePresence>
              {isError && (
                <Box
                  mt={6}
                  overflowY="scroll"
                  maxH={800}
                  css={{
                    '&::-webkit-scrollbar': {
                      width: '4px',
                    },
                    '&::-webkit-scrollbar-track': {
                      width: '6px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      background: '#cccccc',
                      borderRadius: '24px',
                    },
                  }}
                >
                  <FormErrors errors={(error as FormErrorType).data?.detail} />
                </Box>
              )}
            </AnimatePresence>

            <Button mt={4} variant="purple" type="submit" isDisabled={!(paymentDate && file)}>
              Submit
            </Button>
          </form>
        </Content>
      </MsalAuthenticationTemplate>
    </Box>
  );
};

export default Form;
