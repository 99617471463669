import { useRef } from 'react';
import { Select, components, DropdownIndicatorProps, GroupBase, MultiValue, SingleValue } from 'chakra-react-select';
import {
  BsCaretDownFill,
  BsChevronDoubleLeft,
  BsChevronLeft,
  BsChevronRight,
  BsChevronDoubleRight,
} from 'react-icons/bs';
import { Flex, Text, Button, Icon, HStack, Spacer } from '@chakra-ui/react';
import { Table as TableType } from '@tanstack/table-core/build/lib/types';
import { PageSize } from '@/features/common/table/helper.ts';

export const DropdownIndicator = <Option, IsMulti extends boolean, Group extends GroupBase<Option>>(
  props: DropdownIndicatorProps<Option, IsMulti, Group>,
) => {
  return (
    <components.DropdownIndicator {...props}>
      <BsCaretDownFill />
    </components.DropdownIndicator>
  );
};

interface PaginationComponentProps<T extends object> {
  table: TableType<T>;
  pageSize: PageSize | null;
  handlePageSize?: (val: PageSize) => void;
  pageOptions: PageSize[];
  total: number;
}

const PaginationComponent = <T extends object>({
  table,
  pageSize,
  handlePageSize,
  pageOptions,
  total,
}: PaginationComponentProps<T>) => {
  const portalRef = useRef(null);
  const handlePageSizeChange = (val: MultiValue<PageSize> | SingleValue<PageSize>) => {
    if (val && 'value' in val && val.value) {
      table.setPageSize(val.value);
      if (typeof handlePageSize === 'function') {
        handlePageSize(val);
      }
    }
  };

  return (
    <HStack width="100%" mt={'40px'}>
      <Flex justify="start">
        <Text mr={6} fontSize="sm">
          Total <strong>{total}</strong>
        </Text>
      </Flex>
      <Spacer />
      <Flex ref={portalRef} align={'center'} justify={'end'} gap={'10px'}>
        <Text fontSize="sm" mr={-1}>
          Rows per page
        </Text>
        <Select
          focusBorderColor="brand.700"
          aria-label="page-select"
          components={{ DropdownIndicator: DropdownIndicator }}
          chakraStyles={{
            singleValue: (defaultStyles) => ({
              ...defaultStyles,
              fontWeight: 'bold',
              cursor: 'pointer',
            }),
          }}
          styles={{
            dropdownIndicator: (provided) => ({
              ...provided,
              padding: '3px',
              marginRight: '10px',
            }),
          }}
          variant={'unstyled'}
          placeholder="20"
          size="sm"
          isSearchable={false}
          menuPosition="fixed"
          menuPortalTarget={portalRef.current}
          value={pageSize}
          onChange={handlePageSizeChange}
          options={pageOptions}
        />
        <Text mr={6} fontSize="sm">
          Page{' '}
          <strong>
            {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
          </strong>{' '}
        </Text>
        <Button size={'xs'} onClick={() => table.setPageIndex(0)} isDisabled={!table.getCanPreviousPage()}>
          <Icon boxSize={4} as={BsChevronDoubleLeft} />
        </Button>{' '}
        <Button size={'xs'} onClick={() => table.previousPage()} isDisabled={!table.getCanPreviousPage()}>
          <Icon boxSize={4} as={BsChevronLeft} />
        </Button>{' '}
        <Button size={'xs'} onClick={() => table.nextPage()} isDisabled={!table.getCanNextPage()}>
          <Icon boxSize={4} as={BsChevronRight} />
        </Button>{' '}
        <Button
          size={'xs'}
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          isDisabled={!table.getCanNextPage()}
        >
          <Icon boxSize={4} as={BsChevronDoubleRight} />
        </Button>{' '}
      </Flex>
    </HStack>
  );
};

export default PaginationComponent;
