import { cashAppApi } from '@/app/services/cashAppApi.ts';
import { REQUESTS } from '@/utils/constants';

export interface RequestForm {
  file: File;
  paymentDate: string;
}

export interface RequestItem {
  id: string;
  inserted_at: string;
  updated_at: string;
  deleted_at: string;
  submitted_by: string;
  status: RequestStatus;
  file_path: string;
  completed_at: string;
  memo: string;
  payment_date: string;
  payment_date_from: string;
  payment_date_to: string;
  ts_vector: string;
  file: string;
  file_name: string;
  color: string;
}

export enum RequestStatus {
  SUBMITTED = 'Submitted',
  COMPLETED = 'Completed',
  ERROR = 'Error',
}

export interface RequestList {
  items: RequestItem[];
  total: number;
}

export interface RequestParams {
  search?: string;
  submittedBy?: string;
  status?: RequestStatus;
  fileName?: string;
  sortBy?: string;
  limit: number;
  offset: number;
  paymentDateFrom?: string;
  paymentDateTo?: string;
}

const requestApi = cashAppApi.injectEndpoints({
  endpoints: (build) => ({
    request: build.mutation<RequestItem, RequestForm>({
      query: ({ file, paymentDate }) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('payment_date', paymentDate);
        return {
          url: 'requests',
          method: 'POST',
          body: formData,
          formData: true,
        };
      },
      invalidatesTags: [REQUESTS],
    }),
    getRequests: build.query<RequestList, RequestParams>({
      query: ({ search, submittedBy, status, fileName, sortBy, limit, offset, paymentDateFrom, paymentDateTo }) => {
        let query = '';
        if (search) {
          query = `${query}search=${search}&`;
        }
        if (submittedBy) {
          query = `${query}submitted_by=${submittedBy}&`;
        }
        if (status) {
          query = `${query}status=${status}&`;
        }
        if (fileName) {
          query = `${query}file_name=${fileName}&`;
        }
        if (paymentDateFrom && paymentDateTo) {
          query = `${query}payment_date_from=${paymentDateFrom}&payment_date_to=${paymentDateTo}&`;
        }
        if (sortBy) {
          query = `${query}sort_by=${sortBy}&`;
        } else {
          query = `${query}sort_by=-inserted_at&`;
        }
        if (limit) {
          query = `${query}limit=${limit}&`;
        }
        if (offset) {
          query = `${query}offset=${offset}&`;
        }
        if (query.endsWith('&')) {
          query = query.slice(0, -1);
        }
        return {
          url: `requests?${query}`,
          method: 'GET',
        };
      },
      transformResponse: (response: RequestList) => {
        const items = response.items.map((item) => {
          let statusColor = 'white';
          if (item.status === RequestStatus.ERROR) {
            statusColor = 'red.25';
          }
          return {
            ...item,
            color: statusColor,
          };
        });
        return {
          ...response,
          items,
        };
      },
      providesTags: [REQUESTS],
    }),
  }),
  overrideExisting: true,
});
export const { useRequestMutation, useGetRequestsQuery } = requestApi;
