import { useState } from 'react';
import { OnChangeFn, PaginationState } from '@tanstack/react-table';

interface PaginationHook {
  limit: number;
  offset: number;
  pagination: PaginationState;
  onPaginationChange: OnChangeFn<PaginationState>;
}

export const usePagination = (): PaginationHook => {
  const [pagination, setPagination] = useState({
    pageSize: 10,
    pageIndex: 0,
  });
  const { pageSize, pageIndex } = pagination;

  return {
    limit: pageSize,
    onPaginationChange: setPagination,
    pagination,
    offset: pageSize * pageIndex,
  };
};
