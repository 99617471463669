export const addColorData = (rowIndex: number, data: any, value: string) => {
  return data.map((row: any, index: number) => {
    if (index === rowIndex) {
      return {
        ...row,
        color: value,
      };
    }
    return row;
  });
};

export interface TableData<T extends object> {
  rowIndex: number;
  columnId: string;
  value: string;
  data: T[];
}

export interface ColMeta {
  isNumeric?: boolean;
}

export interface PageSize {
  value: number;
  label: number;
}

export const updateTableData = <TData>(rowIndex: number, columnId: string, data: TData[], value: any): TData[] => {
  return data.map((row: any, index: number) => {
    if (index === rowIndex) {
      return {
        ...row,
        [columnId]: value,
      };
    }
    return row;
  });
};
