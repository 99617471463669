import { Flex, Spinner } from '@chakra-ui/react';

const Loading = () => {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      width="100%"
      height="100%"
      aria-label="loading"
      pos="absolute"
      bg="rgba(255,255,255,0.6)"
    >
      <Spinner color="brand.900" size="lg" />
    </Flex>
  );
};

export default Loading;
