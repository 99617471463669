import { PayloadAction } from '@reduxjs/toolkit';
import { createAppSlice } from '@/app/createAppSlice';
import { RootState } from '@/app/store.ts';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

export interface LogMessage {
  msg: string;
  status?: 'error' | 'success' | 'warning';
}

export interface NotificationSliceState {
  logMessage: LogMessage;
  error: FetchBaseQueryError | { message?: string };
}

const initialState: NotificationSliceState = {
  logMessage: { msg: '', status: 'error' },
  error: {},
};

export const notificationSlice = createAppSlice({
  name: 'notification',
  initialState,
  reducers: (create) => ({
    setError: create.reducer((state, action: PayloadAction<FetchBaseQueryError | { message?: string }>) => {
      state.error = action.payload;
    }),
    setLogMessage: create.reducer((state, action: PayloadAction<LogMessage>) => {
      state.logMessage = action.payload;
    }),
  }),
});

// reducers
export const { setError, setLogMessage } = notificationSlice.actions;

// selectors
export const selectError = (state: RootState) => state.notification.error;
export const selectLogMessage = (state: RootState) => state.notification.logMessage;

export default notificationSlice.reducer;
