import React, { useEffect, useMemo, useRef, useState } from 'react';
import { loginRequest } from '@/app/services/cashAppApi';
import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import {
  Badge,
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  useColorModeValue,
} from '@chakra-ui/react';
import ErrorComponent from '../common/ErrorComponent';
import Loading from '../common/Loading';
import Content from '@/features/common/Content.tsx';
import { RequestItem, RequestStatus, useGetRequestsQuery } from './requestsApi';
import { usePagination } from '@/hooks/usePagination';
import ErrorAlert from '@/features/common/ErrorAlert.tsx';
import DataTable, { SortingState } from '@/features/common/table/DataTable.tsx';
import { ColumnDef, createColumnHelper, OnChangeFn } from '@tanstack/react-table';
import { useGetUsersQuery } from '@/features/users/usersApi.ts';
import { Select, SingleValue } from 'chakra-react-select';
import FormErrors from '@/features/requests/FormErrors.tsx';
import { FormErrorType } from '@/app/services/helpers.ts';
import { BsSearch } from 'react-icons/bs';
import TruncateText from '@/features/common/TruncateText.tsx';
import DateRangePicker, { type DateRange } from 'rsuite/DateRangePicker';
import { CustomProvider } from 'rsuite';
import 'rsuite/DateRangePicker/styles/index.css';
import '@/utils/datepicker-theme.less';

type OptionType = { label: string; value: string };
type StatusOptionType = { label: RequestStatus; value: string };

const Queue = () => {
  const timerRef = useRef<number | null>(null);
  const [pageCount, setPageCount] = useState<number>(1);
  const [fileSearch, setFileSearch] = useState<string>('');
  const [file, setFile] = useState<string>('');
  const [sorting, setSorting] = useState<SortingState>([]);
  const [submittedBy, setSubmittedBy] = useState<OptionType | null>(null);
  const [status, setStatus] = useState<StatusOptionType | null>(null);
  const [paymentDate, setPaymentDate] = useState<DateRange | null | undefined>(null);

  const helper = createColumnHelper<RequestItem>();
  const { limit, onPaginationChange, pagination: paginationState, offset } = usePagination();
  const labelColor = useColorModeValue('gray.500', 'gray.400');

  const { data: users, isLoading: isUsersLoading, isError: isUsersError, error: usersError } = useGetUsersQuery();

  const {
    data: requests,
    isLoading,
    isError,
    error,
  } = useGetRequestsQuery({
    sortBy: sorting.map((s) => `${s.desc ? '-' : ''}${s.id}`).join(','),
    submittedBy: submittedBy?.value,
    status: status?.label,
    search: file,
    paymentDateFrom: paymentDate?.[0]?.toLocaleDateString('sv-SE'),
    paymentDateTo: paymentDate?.[1]?.toLocaleDateString('sv-SE'),
    limit,
    offset,
  });

  const userOptions: OptionType[] | undefined = useMemo(() => {
    return users?.map((user) => ({
      label: `${user.first_name} ${user.last_name}`,
      value: user.id,
    }));
  }, [users]);

  const statusOptions: StatusOptionType[] | undefined = useMemo(() => {
    return Object.entries(RequestStatus).map(([key, value]) => ({
      label: value,
      value: key,
    }));
  }, []);

  const handleSortingChange: OnChangeFn<SortingState> = (newSorting) => {
    console.log(newSorting);
    setSorting(newSorting);
  };

  const getSubmittedBy = (id: string) => {
    const user = users && users.length > 0 && users?.find((user) => user.id === id);
    return user ? `${user.first_name} ${user.last_name}` : '';
  };

  useEffect(() => {
    if (requests && requests?.items.length > 0) {
      setPageCount(Math.ceil(requests.total / limit));
    }
  }, [requests?.items]);

  const getBadgeColor = (status: RequestStatus) => {
    switch (status) {
      case RequestStatus.SUBMITTED:
        return 'yellow';
      case RequestStatus.COMPLETED:
        return 'green';
      case RequestStatus.ERROR:
        return 'red';
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFileSearch(event.target.value);
    if (timerRef.current) window.clearTimeout(timerRef.current);
    timerRef.current = window.setTimeout(() => {
      setFile(event.target.value);
    }, 500);
  };

  const handleSubmittedByChange = (option: SingleValue<OptionType>) => {
    setSubmittedBy(option);
  };

  const handleStatusChange = (option: SingleValue<StatusOptionType>) => {
    setStatus(option);
  };

  const columns = useMemo<ColumnDef<RequestItem, any>[]>(
    () => [
      helper.accessor('submitted_by', {
        header: 'Submitted By',
        enableSorting: false,
        cell: (info) => {
          const id = info.getValue();
          return getSubmittedBy(id);
        },
      }),
      helper.accessor('status', {
        header: 'Status',
        enableSorting: false,
        cell: (info) => {
          const status = info.getValue();
          return <Badge colorScheme={getBadgeColor(status)}>{status}</Badge>;
        },
      }),
      helper.accessor('memo', {
        header: 'Memo',
        size: 275,
        cell: (info) => <TruncateText title="Memo" text={info.getValue()} length={60} />,
      }),
      helper.accessor('file_name', {
        header: 'File',
        cell: (info) => info.getValue(),
        enableSorting: false,
      }),
      helper.accessor('payment_date', {
        header: 'Payment Date',
        cell: (info) => {
          const payment = info.getValue();
          return payment ? new Date(payment).toLocaleDateString('en-US', { timeZone: 'UTC' }) : null;
        },
      }),
      helper.accessor('inserted_at', {
        header: 'Submitted',
        cell: (info) => {
          const submitted = info.getValue();
          return submitted ? new Date(submitted).toLocaleString() : null;
        },
      }),
      helper.accessor('file_path', {
        header: 'Download File',
        cell: ({ row }) => {
          return (
            <Link aria-label="download file" href={row.original.file} isExternal>
              <Button size="sm" variant="outline">
                Download
              </Button>
            </Link>
          );
        },
      }),
    ],
    [users],
  );

  return (
    <Box>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={loginRequest}
        errorComponent={ErrorComponent}
        loadingComponent={Loading}
      >
        <Content header="Request Queue">
          <Box>
            {isUsersError ? (
              <ErrorAlert error={usersError} />
            ) : (
              <Box>
                <HStack p={4} gap={6} mb={10}>
                  <FormControl>
                    <FormLabel color={labelColor} fontSize="md" fontWeight={300} htmlFor="submitted-by" mb={0}>
                      Submitted By:
                    </FormLabel>
                    <InputGroup data-testid="submitted-by" display="block" sx={{ zIndex: 100 }}>
                      <Select
                        id="submitted-by"
                        focusBorderColor="brand.700"
                        aria-label="submitted by"
                        chakraStyles={{
                          singleValue: (defaultStyles) => ({
                            ...defaultStyles,
                            fontWeight: 'bold',
                            cursor: 'pointer',
                          }),
                        }}
                        placeholder="Filter by Submitted By"
                        isSearchable={true}
                        isClearable={true}
                        value={submittedBy}
                        onChange={handleSubmittedByChange}
                        options={userOptions}
                      />
                    </InputGroup>
                  </FormControl>
                  <FormControl>
                    <FormLabel color={labelColor} fontSize="md" fontWeight={300} htmlFor="status" mb={0}>
                      Status:
                    </FormLabel>
                    <InputGroup data-testid="status-filter" display="block" sx={{ zIndex: 100 }}>
                      <Select
                        id="status"
                        focusBorderColor="brand.700"
                        aria-label="status filter"
                        chakraStyles={{
                          singleValue: (defaultStyles) => ({
                            ...defaultStyles,
                            fontWeight: 'bold',
                            cursor: 'pointer',
                          }),
                        }}
                        placeholder="Filter by Status"
                        isSearchable={true}
                        isClearable={true}
                        value={status}
                        onChange={handleStatusChange}
                        options={statusOptions}
                      />
                    </InputGroup>
                  </FormControl>
                  <FormControl>
                    <FormLabel color={labelColor} fontSize="md" fontWeight={300} htmlFor="file-search" mb={0}>
                      Payment Date
                    </FormLabel>
                    <InputGroup display="block" sx={{ zIndex: 100, width: '100%' }}>
                      <CustomProvider>
                        <DateRangePicker
                          placeholder="Select Date Range"
                          format="MM/dd/yyyy"
                          value={paymentDate}
                          onChange={setPaymentDate}
                          size="lg"
                        />
                      </CustomProvider>
                    </InputGroup>
                  </FormControl>
                  <FormControl>
                    <FormLabel color={labelColor} fontSize="md" fontWeight={300} htmlFor="file-search" mb={0}>
                      File:
                    </FormLabel>
                    <InputGroup display="block" sx={{ zIndex: 100 }}>
                      <InputLeftElement pointerEvents="none">
                        <BsSearch aria-label="find icon" />
                      </InputLeftElement>
                      <Input
                        id="file-search"
                        focusBorderColor={'brand.700'}
                        aria-label="file-search"
                        placeholder="Search for File"
                        value={fileSearch}
                        onChange={handleFileChange}
                        size="md"
                        type="text"
                      />
                    </InputGroup>
                  </FormControl>
                </HStack>
                {isError && <FormErrors errors={(error as FormErrorType).data?.detail} />}
                <DataTable
                  loading={isLoading || isUsersLoading}
                  data={requests?.items}
                  columns={columns}
                  total={Number(requests?.total)}
                  maxWidth="100%"
                  showScrollbar={true}
                  pageCount={pageCount}
                  pagination={paginationState}
                  onPaginationChange={onPaginationChange}
                  sorting={sorting}
                  onSortingChange={handleSortingChange}
                  editRowLoading={false}
                />
              </Box>
            )}
          </Box>
        </Content>
      </MsalAuthenticationTemplate>
    </Box>
  );
};
export default Queue;
