import { ReactNode } from 'react';
import { Box, useColorModeValue } from '@chakra-ui/react';
import TopNav from './TopNav';

interface LayoutProps {
  pageTitle?: string;
  children: ReactNode;
}

const Layout = ({ pageTitle, children }: LayoutProps) => {
  const bg = useColorModeValue('gray.50', 'gray.900');

  return (
    <Box minH="100vh" bg={bg}>
      <TopNav pageTitle={pageTitle} />
      <Box p={10} pos="relative">
        {children}
      </Box>
    </Box>
  );
};

export default Layout;
