import React, { useState, useEffect, useRef } from 'react';
import { InputGroup, InputLeftElement, Input, FormErrorMessage } from '@chakra-ui/react';
import { FiFile } from 'react-icons/fi';

interface FileUploadProps {
  id: string;
  fileLoadedEvent: (file: File | null) => void;
  uploadSuccess: boolean;
  uploadError: boolean;
  errorMessage: string;
  isReset?: boolean;
  isDisabled: boolean;
  handleClear?: () => void;
}

const FileUpload: React.FC<FileUploadProps> = ({
  id,
  fileLoadedEvent,
  uploadSuccess,
  uploadError,
  errorMessage,
  isReset,
  isDisabled,
  handleClear,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File | null>(null);

  useEffect(() => {
    if (isDisabled && file !== null) {
      handleClearCSV({ target: { files: null } });
    }
  }, [isDisabled]);

  useEffect(() => {
    if (uploadSuccess) {
      handleClearCSV({ target: { files: null } });
    }
  }, [uploadSuccess, uploadError]);

  useEffect(() => {
    if (isReset) {
      handleClearCSV({ target: { files: null } });
    }
  }, [isReset]);

  const handleClearCSV = (e: React.ChangeEvent<HTMLInputElement> | { target: { files: null } }) => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }
    handleFileChange(e);
    if (typeof handleClear === 'function') handleClear();
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement> | { target: { files: null } }) => {
    let file: File | null = null;
    if (e?.target?.files?.[0]) {
      file = e.target.files[0];
      setFile(file);
    } else {
      setFile(null);
    }
    if (typeof fileLoadedEvent === 'function' && e.target.files) fileLoadedEvent(file);
  };

  return (
    <InputGroup display="block">
      <InputLeftElement pointerEvents="none">
        <FiFile />
      </InputLeftElement>
      <input
        id={id}
        aria-label="choose-file"
        type="file"
        accept=".csv"
        onChange={handleFileChange}
        ref={inputRef}
        style={{ display: 'none' }}
      />
      <Input
        aria-label="facade-input"
        placeholder={file?.name ? file.name : 'Choose file...'}
        onClick={() => inputRef?.current?.click()}
        isDisabled={isDisabled}
      />
      <FormErrorMessage mt={4} sx={{ flexBasis: '100%', height: 0 }}>
        {errorMessage}
      </FormErrorMessage>
    </InputGroup>
  );
};

export default FileUpload;
