import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Center,
  Divider,
  Flex,
  FlexProps,
  Heading,
  HStack,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { NavLink as RouterLink } from 'react-router-dom';
import triage from '../../../public/triage.svg';
import { FiChevronDown } from 'react-icons/fi';
import { useAppSelector } from '@/app/hooks';
import { selectFullName } from '../users/userSlice';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { signOutUserRedirect } from '@/app/services/cashAppApi';
import { getGraphProfilePic } from '@/app/services/msGraphApi.ts';
import { InteractionRequiredAuthError, InteractionType } from '@azure/msal-browser';

interface TopNavProps extends FlexProps {
  pageTitle?: string;
}

const TopNav = ({ pageTitle, ...rest }: TopNavProps) => {
  const white = useColorModeValue('white', 'gray.900');
  const gray = useColorModeValue('gray.300', 'gray.700');
  const name = useAppSelector(selectFullName);
  const { instance, accounts, inProgress } = useMsal();
  const [graphData, setGraphData] = useState<any>(null);
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (!graphData && inProgress === InteractionType.None) {
      getGraphProfilePic()
        .then((response) => {
          setGraphData(response);
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance
              .acquireTokenRedirect({
                scopes: ['User.Read'],
                account: instance.getAllAccounts()[0],
              })
              .catch((e) => console.log(e));
          }
        });
    }
  }, [inProgress, graphData, instance]);

  const linkStyles = {
    '&.active': {
      color: 'gray.700',
      fontWeight: 'bold',
    },
    color: 'gray.600',

    '&:hover': {
      textDecoration: 'none',
      color: 'gray.700',
    },
  };

  const handleSignOut = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const account = accounts[0];
    signOutUserRedirect(account, instance);
  };

  return (
    <Flex
      ml={{ base: 0 }}
      px={{ base: 4, md: 6 }}
      height="20"
      alignItems={'center'}
      bg={white}
      borderBottomWidth="5px"
      borderBottomColor="brand.900"
      gap={4}
      {...rest}
    >
      <Box p={0}>
        <Link as={RouterLink} to="/">
          <img src={triage} alt="triage logo" width="100" style={{ width: '100px' }} />
        </Link>
      </Box>
      <Center height="50px">
        <Divider orientation="vertical" />
      </Center>
      <Heading as="h1" size="sm" color="gray.500">
        {pageTitle}
      </Heading>

      <Flex flex="1" justifyContent="center">
        <HStack spacing={4}>
          <Link as={RouterLink} to="/request_form" sx={linkStyles}>
            Request Form
          </Link>
          <Link as={RouterLink} to="/request_queue" sx={linkStyles}>
            Request Queue
          </Link>
        </HStack>
      </Flex>

      <Flex justifyContent={'flex-end'} ml="auto" width="250px">
        <HStack spacing={{ base: 0, md: '6' }}>
          <Menu>
            <MenuButton aria-label="dropdown menu" py={2} transition="all 0.3s" _focus={{ boxShadow: 'none' }}>
              <HStack>
                <Avatar name={name ?? ''} src={graphData?.toString('base64')} />
                <VStack display={{ base: 'none', md: 'flex' }} alignItems="flex-start" spacing="1px" ml="2" minW="75px">
                  <Text fontSize="sm">{name}</Text>
                  <Text fontSize="xs" color="gray.600">
                    {isAuthenticated ? 'Cash App User' : 'Logged Out'}
                  </Text>
                </VStack>
                <Box display={{ base: 'none', md: 'flex' }}>
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList bg={white} borderColor={gray}>
              <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
            </MenuList>
          </Menu>
        </HStack>
      </Flex>
    </Flex>
  );
};

export default TopNav;
