import { chakra, Flex, Spinner, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { ColumnDef, flexRender, Table as TableType } from '@tanstack/react-table';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { ColMeta } from '@/features/common/table/helper.ts';

interface TableComponentProps<TData extends object, TValue> {
  table: TableType<TData>;
  columns: ColumnDef<TData, TValue>[];
  showNoResults: boolean | undefined;
  maxWidth: string;
  rowLoading: boolean;
}

const TableComponent = <TData extends object, TValue>({
  table,
  columns,
  showNoResults,
  maxWidth,
  rowLoading,
}: TableComponentProps<TData, TValue>) => {
  return (
    <Table variant="simple" maxWidth={maxWidth || '100%'} aria-label="data grid">
      <Thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <Tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              const columnDef = header.column.columnDef;
              const meta = columnDef.meta as ColMeta;
              return (
                <Th
                  key={header.id}
                  onClick={header.column.getToggleSortingHandler()}
                  isNumeric={meta?.isNumeric}
                  style={{ width: header.getSize() }}
                >
                  <chakra.span style={{ cursor: 'pointer' }}>
                    {flexRender(header.column.columnDef.header, header.getContext())}

                    <chakra.span pl="4">
                      {header.column.getIsSorted() ? (
                        header.column.getIsSorted() === 'desc' ? (
                          <BsChevronDown aria-label="sorted descending" style={{ display: 'inline-block' }} />
                        ) : (
                          <BsChevronUp aria-label="sorted ascending" style={{ display: 'inline-block' }} />
                        )
                      ) : null}
                    </chakra.span>
                  </chakra.span>
                </Th>
              );
            })}
          </Tr>
        ))}
      </Thead>
      <Tbody>
        {showNoResults ? (
          <Tr>
            <Td colSpan={columns.length} textAlign={'center'}>
              No Results
            </Td>
          </Tr>
        ) : (
          table.getRowModel().rows.map((row) => (
            <Tr key={row.id} bgColor={'color' in row.original && row?.original?.color ? row.original.color : 'white'}>
              {'edit' in row.original && row?.original?.edit && rowLoading ? (
                <Td colSpan={columns.length}>
                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    width="100%"
                    height="40px"
                    aria-label="loading"
                    pos="absolute"
                    bg="rgba(255,255,255,0.6)"
                  >
                    <Spinner color="brand.900" size="lg" />
                  </Flex>
                </Td>
              ) : (
                row.getVisibleCells().map((cell) => {
                  const meta = cell.column.columnDef.meta as ColMeta;
                  return (
                    <Td
                      key={cell.id}
                      isNumeric={meta?.isNumeric}
                      style={{ whiteSpace: 'break-spaces', wordWrap: 'break-word', height: '1px' }}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </Td>
                  );
                })
              )}
            </Tr>
          ))
        )}
      </Tbody>
    </Table>
  );
};
export default TableComponent;
