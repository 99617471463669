import { createSelector, PayloadAction } from '@reduxjs/toolkit';
import { createAppSlice } from '@/app/createAppSlice';
import { CheckinResponse } from '@/features/users/usersApi.ts';
import { RootState } from '@/app/store.ts';
export interface UserSliceState {
  id: string;
  firstName: string;
  lastName: string;
  fullName: string;
  title: string;
  email: string;
  token: string;
}

const initialState: UserSliceState = {
  id: '',
  firstName: '',
  lastName: '',
  fullName: '',
  title: '',
  email: '',
  token: '',
};

export const userSlice = createAppSlice({
  name: 'user',
  initialState,
  reducers: (create) => ({
    setToken: create.reducer((state, action: PayloadAction<string>) => {
      state.token = action.payload;
    }),
    setUserTitle: create.reducer((state, action: PayloadAction<string>) => {
      state.title = action.payload;
    }),
    setUser: create.reducer((state, action: PayloadAction<CheckinResponse>) => {
      state.id = action.payload.id;
      state.firstName = action.payload.first_name;
      state.lastName = action.payload.last_name;
      state.fullName = `${action.payload.first_name} ${action.payload.last_name}`;
      state.email = action.payload.email;
    }),
  }),
  selectors: {},
});

// reducers
export const { setUser, setToken, setUserTitle } = userSlice.actions;

// selectors
export const selectId = (state: RootState) => state.user.id;
export const selectToken = (state: RootState) => state.user.token;
export const selectUserTitle = (state: RootState) => state.user.title;
export const selectFirstName = (state: RootState) => state.user.firstName;
export const selectLastName = (state: RootState) => state.user.lastName;
export const selectFullName = (state: RootState) => state.user.fullName;
export const selectEmail = (state: RootState) => state.user.email;
export const selectUser = createSelector(
  [selectId, selectFirstName, selectLastName, selectEmail],
  (id, firstName, lastName, email) => ({
    id,
    firstName,
    lastName,
    email,
  }),
);

export default userSlice.reducer;
