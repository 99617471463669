const tags = {
  REQUESTS: 'Requests',
} as const;
export const { REQUESTS } = tags;

export interface PageOption {
  label: number;
  value: number;
}
export const pageOptions: PageOption[] = [
  { label: 10, value: 10 },
  { label: 20, value: 20 },
  { label: 50, value: 50 },
  { label: 100, value: 100 },
];

export const scrollbar: { [key: string]: any } = {
  '&::-webkit-scrollbar': {
    height: '5px',
  },
  '&::-webkit-scrollbar-track': {
    height: '6px',
    background: '#f7f7f7',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#cccccc',
    borderRadius: '24px',
  },
};
