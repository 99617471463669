import { Text } from '@chakra-ui/react';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { useAppSelector } from '@/app/hooks.ts';
import { selectUser } from '@/features/users/userSlice.ts';
import Unauthenticated from '@/features/common/Unauthenticated.tsx';
import Loading from '@/features/common/Loading.tsx';
import Content from '@/features/common/Content.tsx';

const HomePage = () => {
  const user = useAppSelector(selectUser);

  return (
    <>
      <AuthenticatedTemplate>
        {user?.email ? (
          <Content header="Welcome">
            <Text as="p">Hello, welcome to Cash App</Text>
            <Text as="p">
              You are logged in as <strong>{user?.email}</strong>
            </Text>
          </Content>
        ) : (
          <Loading />
        )}
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <Unauthenticated />
      </UnauthenticatedTemplate>
    </>
  );
};

export default HomePage;
