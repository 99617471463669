import { useAppDispatch, useAppSelector } from '@/app/hooks.ts';
import { selectToken, setToken, setUser } from '@/features/users/userSlice.ts';
import { useAccount, useMsal } from '@azure/msal-react';
import React, { useEffect } from 'react';
import { loginRequest } from '@/app/services/cashAppApi.ts';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { useCheckinMutation } from '@/features/users/usersApi.ts';
import ErrorAlert from '@/features/common/ErrorAlert';
import { Outlet } from 'react-router-dom';

export function PrivateOutlet() {
  const dispatch = useAppDispatch();
  const token = useAppSelector(selectToken);
  const [checkin, { isError, error }] = useCheckinMutation();

  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  useEffect(() => {
    if (account && !token) {
      const accessTokenRequest = {
        scopes: loginRequest.scopes,
        account: accounts[0],
      };
      instance
        .acquireTokenSilent(accessTokenRequest)
        .then((response) => {
          dispatch(setToken(response.accessToken));
          checkin()
            .unwrap()
            .then((res) => {
              dispatch(setUser(res));
            })
            .catch((error) => console.error(error));
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance
              .acquireTokenPopup(accessTokenRequest)
              .then((response) => {
                const accessToken = response.accessToken;
                dispatch(setToken(accessToken));
                checkin()
                  .unwrap()
                  .then((res) => {
                    dispatch(setUser(res));
                  });
              })
              .catch((error) => console.error(error));
          }
          console.error(error);
        });
    }
  }, [instance, token, account]);

  return <React.Fragment>{isError ? <ErrorAlert error={error} /> : <Outlet />}</React.Fragment>;
}
