import { useEffect, useState } from 'react';
import { Flex, Spinner, TableContainer } from '@chakra-ui/react';
import { useReactTable, getCoreRowModel, PaginationState, OnChangeFn, ColumnDef } from '@tanstack/react-table';
import { scrollbar, pageOptions } from '@/utils/constants';
import { addColorData, PageSize, updateTableData } from '@/features/common/table/helper';
import TableComponent from '@/features/common/table/TableComponent.tsx';
import PaginationComponent from '@/features/common/table/PaginationComponent.tsx';

export type ColumnSort = {
  id: string;
  desc: boolean;
};
export type SortingState = ColumnSort[];

interface DataTableProps<TData extends object, TValue> {
  loading: boolean;
  data: TData[] | undefined;
  columns: ColumnDef<TData, TValue>[];
  total: number;
  maxWidth: string;
  showScrollbar: boolean;
  pageCount: number;
  pagination: PaginationState;
  sorting: SortingState;
  onPaginationChange: OnChangeFn<PaginationState>;
  onSortingChange: OnChangeFn<SortingState>;
  editRowLoading: boolean;
}

const DataTable = <TData extends object, TValue>({
  loading,
  data,
  columns,
  total,
  maxWidth,
  showScrollbar,
  pageCount,
  pagination,
  sorting,
  onPaginationChange,
  onSortingChange,
  editRowLoading,
}: DataTableProps<TData, TValue>) => {
  const [pageSize, setPageSize] = useState<PageSize | null>(null);
  const [tableData, setTableData] = useState<TData[]>([]);

  useEffect(() => {
    if (data) {
      setTableData(() => [...data]);
    }
  }, [data]);

  useEffect(() => {
    if (pagination?.pageSize) {
      setPageSize({ value: pagination.pageSize, label: pagination.pageSize });
    }
  }, [pagination]);

  const table = useReactTable({
    columns,
    data: tableData,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    manualSorting: true,
    onPaginationChange,
    onSortingChange,
    meta: {
      updateData: (rowIndex: number, columnId: string, value: any) => {
        setTableData((old) => updateTableData(rowIndex, columnId, old, value));
      },
      resetRowData: (rowIndex: number) => {
        setTableData((old) =>
          old.map((row, index) => {
            if (index === rowIndex) {
              if (data) {
                return {
                  ...data[index],
                };
              }
            }
            return row;
          }),
        );
      },
      addRowColor: (rowIndex: number, value: any) => {
        setTableData((old) => addColorData(rowIndex, old, value));
      },
      addEditFlag: (rowIndex: number, value: any) => {
        setTableData((old) =>
          old.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...row,
                edit: value,
              };
            }
            return row;
          }),
        );
      },
    },
    state: { pagination, sorting },
    pageCount,
  });

  const handlePageSize = (val: PageSize) => {
    setPageSize(val);
  };

  return (
    <TableContainer css={showScrollbar && scrollbar} width={'100%'}>
      {loading ? (
        <Flex alignItems="center" justifyContent="center" mt={20}>
          <Spinner color="brand.900" size="lg" />
        </Flex>
      ) : (
        <TableComponent
          rowLoading={editRowLoading}
          table={table}
          columns={columns}
          showNoResults={!loading && data && data.length === 0}
          maxWidth={maxWidth}
        />
      )}
      {data && total >= 10 && !loading && (
        <PaginationComponent
          table={table}
          pageSize={pageSize}
          handlePageSize={handlePageSize}
          pageOptions={pageOptions}
          total={total}
        />
      )}
    </TableContainer>
  );
};

export default DataTable;
