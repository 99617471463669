import {
  AccountInfo,
  Configuration,
  IPublicClientApplication,
  LogLevel,
  PublicClientApplication,
} from '@azure/msal-browser';
import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { hostname } from '@/app/services/helpers';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { RootState } from '../store';
import { setToken } from '@/features/users/userSlice';
import { setError, setLogMessage } from '@/features/notification/notificationSlice';
import { REQUESTS } from '@/utils/constants';

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig: Configuration = {
  auth: {
    clientId: import.meta.env.VITE_ADAL_CLIENT_ID!,
    authority: `https://login.microsoftonline.com/${import.meta.env.VITE_ADAL_TENANT}`, // Defaults to "https://login.microsoftonline.com/common"
    redirectUri: window.location.origin,
    postLogoutRedirectUri: window.location.origin, // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: 'localStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii): void => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};
export const msalInstance = new PublicClientApplication(msalConfig);

export const loginRequest = {
  scopes: [import.meta.env.VITE_ADAL_SCOPE],
};
export const errorCodes = {
  forgotKey: 'AADB2C90118',
};

export const signOutUserRedirect = (account: AccountInfo, instance: IPublicClientApplication) => {
  instance
    .logoutRedirect({
      account: account,
      postLogoutRedirectUri: window.location.origin,
    })
    .catch((e) => console.error(e));
};

export const signInUser = (instance: IPublicClientApplication) => {
  instance.loginPopup().catch((e) => console.error(e));
};

const baseQuery = fetchBaseQuery({
  baseUrl: `${hostname}/api/v1/`,
  prepareHeaders: async (headers, { getState }) => {
    const state = getState() as RootState;
    const token = state.user.token;
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  let result = await baseQuery(args, api, extraOptions);
  const status = Number(result?.error?.status);
  // const state = api.getState() as RootState;
  // const errorData = result?.error?.data;
  if (status && result?.error && [401, 429].includes(status)) {
    const refreshToken = await msalInstance.acquireTokenSilent(loginRequest);
    if (refreshToken.accessToken) {
      api.dispatch(setToken(refreshToken.accessToken));
      result = await baseQuery(args, api, extraOptions);
    } else {
      console.error('Error: No token received');
    }
  } else if (result?.error && status === 403) {
    api.dispatch(setLogMessage({ msg: 'Uncaught Error', status: 'error' }));
  }
  if (result?.error && status > 400 && ![401, 429].includes(status)) {
    api.dispatch(setError({ message: 'Uncaught General Error' }));
  }
  return result;
};
export const cashAppApi = createApi({
  baseQuery: baseQueryWithReauth,
  reducerPath: 'cashAppApi',
  tagTypes: [REQUESTS],
  endpoints: () => ({}),
});
