import { cashAppApi } from '@/app/services/cashAppApi.ts';

export interface CheckinResponse {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  inserted_at: string;
  deleted_at: string;
}

export interface User {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  inserted_at: string;
  deleted_at: string;
  updated_at: string;
}

interface UserList {
  items: User[];
}

const usersApi = cashAppApi.injectEndpoints({
  endpoints: (build) => ({
    checkin: build.mutation<CheckinResponse, void>({
      query: () => {
        return {
          url: 'users/check_in',
          method: 'POST',
        };
      },
    }),
    getUsers: build.query<User[], void>({
      query: () => {
        return {
          url: `users?sort_by=last_name&limit=0&offset=0`,
          method: 'GET',
        };
      },
      transformResponse: (response: UserList) => {
        return response.items;
      },
    }),
  }),
  overrideExisting: true,
});

export const { useCheckinMutation, useGetUsersQuery } = usersApi;
