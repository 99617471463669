import { Box, Heading } from '@chakra-ui/react';

const NotFound = () => {
  return (
    <>
      <Heading as="h4" size="md" fontWeight="bold" color="brand.900">
        404 Not Found
      </Heading>
      <Box mt="4" p={0}>
        The page you were looking for does not exist.
      </Box>
    </>
  );
};
export default NotFound;
