import { msalInstance } from './cashAppApi';

export const graphConfig = {
  graphPhotoEndpoint: 'https://graph.microsoft.com/v1.0/me/photo/$value',
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
};

function blobToBase64(blob: Blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
  });
}

async function getFetchOptions(accessToken: string | null | undefined) {
  if (!accessToken) {
    const account = msalInstance.getActiveAccount();
    if (!account) {
      throw Error('No active account! Verify a user has been signed in and setActiveAccount has been called.');
    }

    const response = await msalInstance.acquireTokenSilent({
      scopes: ['User.Read'],
      account: account,
    });
    accessToken = response.accessToken;
  }

  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append('Authorization', bearer);

  return {
    method: 'GET',
    headers: headers,
  };
}

export async function getGraphMe(accessToken?: string | null) {
  const options = await getFetchOptions(accessToken);

  return fetch(graphConfig.graphMeEndpoint, options)
    .then((response) => {
      return response.json();
    })
    .catch((error) => console.log(error));
}

export async function getGraphProfilePic(accessToken?: string | null) {
  const options = await getFetchOptions(accessToken);

  return fetch(graphConfig.graphPhotoEndpoint, options)
    .then((response) => {
      return response.blob();
    })
    .then(blobToBase64)
    .catch((error) => console.log(error));
}
