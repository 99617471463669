import { Box, Heading, HStack, Icon, Text } from '@chakra-ui/react';
import { FiAlertCircle } from 'react-icons/fi';
import { FormError } from '@/app/services/helpers.ts';
import ErrorAlert from '@/features/common/ErrorAlert.tsx';

interface FormErrorsProps {
  errors: FormError[] | string;
}

/**
 * Display form errors
 * This component will display a list of errors if the errors prop is an array of FormError objects
 * or it can also display a single error message if the errors prop is a string
 * @param errors
 */
const FormErrors = ({ errors }: FormErrorsProps) => {
  return Array.isArray(errors) ? (
    <>
      <Heading as="h5" size="md" fontWeight="bold" color="brand.900">
        Errors
      </Heading>
      {errors.map((err, i) => (
        <Box key={err.type + i} mt={6} bgColor="#FFF4F4" p={4}>
          <Box>
            <HStack>
              <Icon boxSize={6} as={FiAlertCircle} color={'red'} />
              <Box maxWidth="sm">
                <Text as="b">{err.msg}</Text>
                <Box>
                  <b>Row: </b>
                  {err.loc[1]}
                </Box>
                {err.loc[2] && (
                  <Box>
                    <b>Field: </b>
                    {err.loc[2]}
                  </Box>
                )}
              </Box>
            </HStack>
          </Box>
        </Box>
      ))}
    </>
  ) : (
    <ErrorAlert error={errors} />
  );
};

export default FormErrors;
