import { useEffect, useState } from 'react';
import { Alert, AlertIcon, AlertTitle, AlertDescription, Box } from '@chakra-ui/react';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { isErrorWithMessage, isFetchBaseQueryError } from '@/app/services/helpers';
import { SerializedError } from '@reduxjs/toolkit';

interface ErrorAlertProps {
  error: FetchBaseQueryError | SerializedError | { message?: string } | string;
}
const ErrorAlert = ({ error }: ErrorAlertProps) => {
  const [desc, setDesc] = useState<string>();

  useEffect(() => {
    if (isFetchBaseQueryError(error)) {
      let errMsg = '';
      if ('error' in error) {
        errMsg = error.error as any;
      } else if ('data' in error) {
        errMsg = (error.data as any).error || (error.data as any).detail;
      }
      setDesc(errMsg);
    } else if (isErrorWithMessage(error)) {
      setDesc(error.message);
    } else {
      setDesc(error as string);
    }
  }, [error]);

  return (
    <Alert status="error" borderRadius={6}>
      <AlertIcon />
      <Box pl={4}>
        <AlertTitle>{'Error'}</AlertTitle>
        <AlertDescription>{desc || 'Please contact an administrator.'}</AlertDescription>
      </Box>
    </Alert>
  );
};
export default ErrorAlert;
