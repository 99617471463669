import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';

const cashAppHost = import.meta.env.VITE_HOST;
export const hostname = cashAppHost ? cashAppHost : 'http://localhost:8000';

/**
 * Type predicate to narrow an unknown error to `FetchBaseQueryError`
 */
export function isFetchBaseQueryError(error: unknown): error is FetchBaseQueryError {
  return typeof error === 'object' && error != null && 'status' in error;
}

/**
 * Type predicate to narrow an unknown error to an object with a string 'message' property
 */
export function isErrorWithMessage(error: unknown): error is { message: string } {
  return typeof error === 'object' && error != null && 'message' in error && typeof (error as any).message === 'string';
}

export interface FormError {
  input: string;
  loc: string[];
  msg: string;
  type: string;
}
export type FormErrorType = {
  data: { detail: FormError[] | string };
};
