import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { Provider } from 'react-redux';
import App from './App';
import { EventType, RedirectRequest } from '@azure/msal-browser';
import { errorCodes, msalInstance } from '@/app/services/cashAppApi.ts';
import { store } from './app/store';
import { theme } from './utils/theme';
import { triageAscii } from '@/features/common/triage-ascii.ts';
import './index.css';

const URL_ADAL_TENANT = `https://login.microsoftonline.com/${import.meta.env.VITE_ADAL_TENANT}`;

async function deferRender() {
  if (import.meta.env.VITE_MSW_DEV === 'true') {
    const { worker } = await import('./mocks/browser');
    return worker.start();
  } else {
    return Promise.resolve();
  }
}

deferRender().then(() => {
  msalInstance.initialize().then(() => {
    if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
      // Account selection logic is app dependent. Adjust as needed for different use cases.
      msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
    }
    // Optional - This will update account state if a user signs in from another tab or window
    msalInstance.enableAccountStorageEvents();
    msalInstance.addEventCallback((event) => {
      if (
        (event.eventType === EventType.LOGIN_SUCCESS ||
          event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
          event.eventType === EventType.SSO_SILENT_SUCCESS) &&
        //@ts-expect-error payload shape is not typed
        event.payload.account
      ) {
        //@ts-expect-error payload shape is not typed
        const account = event.payload.account;
        msalInstance.setActiveAccount(account);
      } else if (event.eventType === EventType.LOGIN_FAILURE) {
        if (event.error?.message.includes(errorCodes.forgotKey)) {
          const authority: RedirectRequest = { authority: URL_ADAL_TENANT, scopes: [] };
          void msalInstance.loginRedirect(authority);
        }
      } else if (
        event.eventType === EventType.ACQUIRE_TOKEN_FAILURE &&
        //@ts-expect-error payload shape is not typed
        !event.error?.errorCode.includes('no_network_connectivity')
      ) {
        void msalInstance.logoutRedirect();
      }
    });

    const root = createRoot(document.getElementById('root') as Element);
    triageAscii();

    root.render(
      <Provider store={store}>
        <ChakraProvider theme={theme}>
          <BrowserRouter>
            <App pca={msalInstance} />
          </BrowserRouter>
        </ChakraProvider>
      </Provider>,
    );
  });
});
