import { Box, Button, Heading, Text } from '@chakra-ui/react';
import { signInUser } from '@/app/services/cashAppApi.ts';
import { useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';

const Unauthenticated = () => {
  const { instance, inProgress } = useMsal();
  return (
    <>
      <Heading as="h4" size="md" fontWeight="bold" color="brand.900">
        Welcome.
      </Heading>
      {inProgress !== InteractionStatus.Startup && inProgress !== InteractionStatus.HandleRedirect && (
        <Box mt={6} p={0}>
          <Text fontSize="md">Please sign-in.</Text>
          <Button my={4} onClick={() => signInUser(instance)}>
            Sign In
          </Button>
        </Box>
      )}
    </>
  );
};
export default Unauthenticated;
