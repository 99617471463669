import { useState, useMemo, useEffect } from 'react';
import {
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  Box,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react';
import { FiMoreHorizontal } from 'react-icons/fi';

interface TruncateTextProps {
  title: string;
  text: string;
  length: number;
}

interface TextData {
  id: string;
  value: string;
}

const cleanString = (text: string) => {
  const noSpecials = text.replace(/[^a-zA-Z ]/g, '');
  return noSpecials.toLowerCase().replace(/ /g, '_');
};

const TruncateText = ({ title, text, length }: TruncateTextProps) => {
  const [marker, setMarker] = useState<number>(0);
  const [showPop, setShowPop] = useState<boolean>(false);

  // create a lowercase all alphabetic string for HTML attributes
  const cleanTitle = useMemo<string>(() => {
    return cleanString(title);
  }, [title]);

  const dataList: TextData[] | null = useMemo(() => {
    if (text && text !== '') {
      const splitList = text.split('\n');
      // create obj with id for React key
      return splitList.map((item) => {
        const id = cleanString(item).slice(0, item.length / 2);
        return {
          id,
          value: item,
        };
      });
    } else {
      return null;
    }
  }, [text]);

  const truncatedString: string = useMemo(() => {
    let tmpString = '';
    if (text && text !== '') {
      const splitList = text.split('\n');
      splitList.forEach((fac, i) => {
        if (marker === 0) {
          tmpString = tmpString === '' ? fac : tmpString + `, ${fac}`;
        }
        if (tmpString.length >= length && marker === 0) {
          const re = new RegExp(`.{${length}}\\w*|.*`, 'g');
          const match = re.exec(tmpString);
          if (match && match[0]) {
            tmpString = match[0];
          }
          setMarker(i + 1);
        }
      });
    }
    return tmpString;
  }, [text]);

  useEffect(() => {
    if (dataList && dataList?.length > 1 && [truncatedString].length < dataList.length) {
      setShowPop(true);
      setMarker(0);
    } else if (truncatedString && dataList && truncatedString?.length < dataList[0].value.length) {
      setShowPop(true);
      setMarker(0);
    } else {
      setShowPop(false);
    }
  }, [truncatedString, dataList]);

  if (showPop) {
    return (
      <Box as="span">
        <Box as="span" aria-label={`truncated ${cleanTitle}`}>
          {truncatedString + ` `}
        </Box>
        <Popover placement="bottom">
          <PopoverTrigger>
            <IconButton
              variant="ghost"
              colorScheme={'cyan'}
              aria-label={`view ${cleanTitle}`}
              icon={<FiMoreHorizontal />}
            />
          </PopoverTrigger>
          <PopoverContent minW={{ base: '100%', lg: '800px' }} aria-label="popover content">
            <PopoverHeader fontWeight="semibold">{title}</PopoverHeader>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverBody>
              <Box
                overflowY="scroll"
                maxH={500}
                css={{
                  '&::-webkit-scrollbar': {
                    width: '4px',
                  },
                  '&::-webkit-scrollbar-track': {
                    width: '6px',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    background: '#cccccc',
                    borderRadius: '24px',
                  },
                }}
              >
                <UnorderedList>
                  {dataList &&
                    dataList.map((facility, i) => {
                      return <ListItem key={`${facility.id}-${i}`}>{facility.value.trimStart()}</ListItem>;
                    })}
                </UnorderedList>
              </Box>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Box>
    );
  } else {
    return (
      <Box as="span" aria-label={cleanTitle}>
        {truncatedString}
      </Box>
    );
  }
};

export default TruncateText;
