import { ReactNode } from 'react';
import { Box, Heading, useColorModeValue } from '@chakra-ui/react';

interface ContentProps {
  header?: string;
  children: ReactNode;
}

const Content = ({ header, children }: ContentProps) => {
  const borderColor = useColorModeValue('gray.100', 'gray.600');
  return (
    <Box>
      <Heading as="h4" size="md" fontWeight="bold" color="brand.900">
        {header}
      </Heading>
      <Box mt="4" p={4} bgColor="white" borderRadius="9" borderColor={borderColor} borderWidth="1px" minHeight="85vh">
        {children}
      </Box>
    </Box>
  );
};

export default Content;
