import { Routes, Route } from 'react-router-dom';
import HomePage from '@/features/homepage/HomePage.tsx';
import Layout from '@/features/common/Layout.tsx';
import { MsalProvider } from '@azure/msal-react';
import { IPublicClientApplication } from '@azure/msal-browser';
import Form from './features/requests/Form';
import Queue from './features/requests/Queue';
import { PrivateOutlet } from '@/utils/PrivateOutlet.tsx';
import { useEffect, useRef } from 'react';
import { ToastId, useToast } from '@chakra-ui/react';
import { LogMessage, selectLogMessage, setLogMessage } from '@/features/notification/notificationSlice.ts';
import { useAppDispatch, useAppSelector } from '@/app/hooks.ts';
import NotFound from './features/common/NotFound';

interface AppProps {
  pca: IPublicClientApplication;
}
const App = ({ pca }: AppProps) => {
  const dispatch = useAppDispatch();
  const toastIdRef = useRef<ToastId | undefined>(undefined);
  const logMessage = useAppSelector(selectLogMessage);
  const toast = useToast();

  const addToast = (notification: LogMessage) => {
    toastIdRef.current = toast({
      position: 'top-right',
      title: '',
      containerStyle: {
        paddingTop: '5rem',
      },
      description: notification.msg,
      status: notification.status,
      variant: 'subtle',
      duration: 3000,
      isClosable: false,
      onCloseComplete: () => {
        const payload: LogMessage = {
          msg: '',
          status: 'error',
        };
        dispatch(setLogMessage(payload));
      },
    });
  };

  useEffect(() => {
    if (logMessage.msg) {
      addToast(logMessage);
    }
  }, [logMessage]);

  return (
    <MsalProvider instance={pca}>
      <Layout pageTitle={'Cash App'}>
        <Pages />
      </Layout>
    </MsalProvider>
  );
};

const Pages = () => {
  return (
    <Routes>
      <Route element={<PrivateOutlet />}>
        <Route path="/" element={<HomePage />} />
        <Route path="/request_form" element={<Form />} />
        <Route path="/request_queue" element={<Queue />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};
export default App;
