import { type Action, combineReducers, type ThunkAction } from '@reduxjs/toolkit';
import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { createLogger } from 'redux-logger';
import userReducer from '@/features/users/userSlice';
import notificationReducer from '@/features/notification/notificationSlice';
import { cashAppApi } from './services/cashAppApi';

const isProduction = import.meta.env.PROD;
const isStaging = import.meta.env.MODE === 'staging' || import.meta.env.MODE === 'qa';

const logger = createLogger({
  diff: true,
  colors: {
    prevState: () => '#7D72B7',
    action: () => '#008AFC',
    nextState: () => '#16C0FF',
    error: () => '#E64747',
  },
  level: 'info',
  collapsed: (_getState, action) => !action.payload,
  predicate: (_getState, action) => !action.type.includes('nucleusApi') && !action.type.includes('__rtkq'),
});

const middleware = [cashAppApi.middleware];
if (!isStaging && !isProduction) {
  middleware.push(logger);
}

const rootReducer = combineReducers({
  user: userReducer,
  notification: notificationReducer,
  [cashAppApi.reducerPath]: cashAppApi.reducer,
});
// Infer the `RootState` type from the root reducer
export type RootState = ReturnType<typeof rootReducer>;

// The store setup is wrapped in `makeStore` to allow reuse
// when setting up tests that need the same store config
export const makeStore = (preloadedState?: Partial<RootState>) => {
  const store = configureStore({
    reducer: rootReducer,
    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`.
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware({ serializableCheck: false }).concat(middleware);
    },
    preloadedState,
    devTools: !isProduction,
  });
  // configure listeners using the provided defaults
  // optional, but required for `refetchOnFocus`/`refetchOnReconnect` behaviors
  setupListeners(store.dispatch);
  return store;
};

export const store = makeStore();

// Infer the type of `store`
export type AppStore = typeof store;
// Infer the `AppDispatch` type from the store itself
export type AppDispatch = AppStore['dispatch'];
export type AppThunk<ThunkReturnType = void> = ThunkAction<ThunkReturnType, RootState, unknown, Action>;
