import { Box, Heading } from '@chakra-ui/react';
import { MsalAuthenticationResult } from '@azure/msal-react';

const ErrorComponent: React.FC<MsalAuthenticationResult> = ({ error }) => {
  return (
    <>
      <Heading as="h4" size="md" fontWeight="bold" color="brand.900">
        Authentication Error
      </Heading>
      <Box mt="4" p={0}>
        An error occurred: {error ? error.errorCode : 'Unknown error'}
      </Box>
    </>
  );
};
export default ErrorComponent;
